<template>
  <BaseDialog minHeight="200px" :title="title" :dialogVisible.sync="dialog" @closeHandle="onClose" width="80vw">
    <template>
      <FedexGoodList :data="data" />
    </template>
    <template #footer>
      <el-button type="primary" @click="doSubmit"> 确认 </el-button>
      <LoadingBtn @click="cancel"> 取消 </LoadingBtn>
    </template>
  </BaseDialog>
</template>
<script>
import FedexGoodList from '../fedexGoodList'

import { commonFromMixin } from '@/mixins'

export default {
  mixins: [commonFromMixin],

  components: {
    FedexGoodList
  },

  props: {
    data: {
      type: Object,
      required: true
    },
    formLoading: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    async doSubmit() {
      this.doEdit()
    },
    onClose() {
      this.$emit('update:formLoading', false)
    },
    async doEdit() {
      const data = Object.values(this.data)
      let list = []
      data.map((item) => {
        const fristObj = item[0]
        item.map((sItem) => {
          for (let key in fristObj) {
            if (!sItem[key] && key != 'id') {
              sItem[key] = fristObj[key]
            }
          }
        })
        list = list.concat(item)
      })
      console.log('list', list)
      this.$emit('updateData', list)
      this.cancel()
    }
  }
}
</script>
